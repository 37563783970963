import React from 'react';
import PropTypes from 'prop-types';
import IconChevronDown from '@svg/icon-chevron-down.svg';
import styles from './SelectInput.module.scss';

function SelectInput({ id, name, label, placeholder, options, onChange }) {
  return (
    <label className={styles.select} htmlFor={id}>
      <span className={styles.selectLabel}>{label}</span>
      <span className={styles.selectContainer}>
        <select
          className={styles.selectInput}
          id={id}
          name={name}
          onChange={onChange}
        >
          <option value="">{placeholder}</option>

          {options &&
            options.map((option, index) => (
              <option value={option} key={`${option}-${index}`}>
                {option}
              </option>
            ))}
        </select>
        <IconChevronDown className={styles.selectIcon} />
      </span>
    </label>
  );
}

SelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectInput;
