import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

const Seo = ({ title, description }) => (
  <Head>
    {title && <title>{title}</title>}
    {description && <meta name="description" content={description} />}
  </Head>
);

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Seo;
