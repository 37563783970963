import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import Button from '@atoms/Button/Button';
import IconCity from '@svg/icon-city.svg';
import IconCompany from '@svg/icon-company.svg';
import IconTime from '@svg/icon-time.svg';
import styles from './VacancyListItem.module.scss';

const VacancyListItem = ({ title, company, city, employmentType, href }) => {
  const { t } = useTranslation(['common']);

  return (
    <Link href={href}>
      <a className={styles.item} aria-label={`${t('viewVacancyFor')} ${title}`}>
        <div className={styles.header}>
          <h3 className={styles.title}>{title}</h3>
          {city && (
            <div className={styles.meta}>
              <IconCity className={styles.icon} />
              {city}
            </div>
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.metaGroup}>
            {company && (
              <div className={styles.meta}>
                <IconCompany className={styles.icon} />
                {company}
              </div>
            )}
            {employmentType && (
              <div className={styles.meta}>
                <IconTime className={styles.icon} />
                {employmentType}
              </div>
            )}
          </div>
          <div className={styles.cta} aria-hidden="true">
            <Button as="span">{t('viewVacancy')}</Button>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default VacancyListItem;

VacancyListItem.propTypes = {
  title: PropTypes.string.isRequired,
  company: PropTypes.string,
  city: PropTypes.string,
  employmentType: PropTypes.string,
  href: PropTypes.string.isRequired,
};
