import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import Card from '@molecules/Card/Card';

const ErrorMessage = ({ message }) => {
  const { t, i18n } = useTranslation('common');
  // Form the error message to a translation key we can use
  const transKey = `errors.${message.replace('Error: ', '').trim()}`;
  // Check if the provided error message has an translation
  const transExists = i18n.exists(`common:${transKey}`);
  // Either show the translated error or just the raw error message
  const errorMessage = transExists ? t(transKey) : message;

  return (
    <Card extraClasses={['error']}>
      <p data-cy="error-message">
        <strong>{t('error')}</strong> {errorMessage}
      </p>
    </Card>
  );
};

const DisplayError = ({ error }) => {
  if (!error || !error.message) return null;

  if (
    error.networkError &&
    error.networkError.result &&
    error.networkError.result.errors &&
    error.networkError.result.errors.length
  ) {
    return error.networkError.result.errors.map((error, i) => (
      <ErrorMessage
        key={i}
        message={error.message.replace('GraphQL error: ', '')}
      />
    ));
  }

  if (error.graphQLErrors && error.graphQLErrors.length) {
    return error.graphQLErrors.map((graphQLError, i) => {
      if (graphQLError.debugMessage) {
        return <ErrorMessage key={i} message={graphQLError.debugMessage} />;
      }

      const messages = graphQLError.message.split('\n');

      return messages.map(message => (
        <ErrorMessage
          key={i}
          message={message.replace('GraphQL error: ', '')}
        />
      ));
    });
  }

  return (
    <ErrorMessage
      message={error.message
        .replace('GraphQL error: ', '')
        .replace('Error: ', '')}
    />
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
};

DisplayError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    networkError: PropTypes.shape({
      result: PropTypes.shape({
        errors: PropTypes.arrayOf(
          PropTypes.shape({
            debugMessage: PropTypes.string,
            message: PropTypes.string,
          })
        ),
      }),
    }),
    graphQLErrors: PropTypes.arrayOf(
      PropTypes.shape({
        debugMessage: PropTypes.string,
        message: PropTypes.string,
      })
    ),
  }),
};

export default DisplayError;
