import React from 'react';
import PropTypes from 'prop-types';
import { withExtraClasses } from '@utils';
import styles from './Loader.module.scss';

// If children are supplied to the loader component, wrap it in a container div
// This will show a semi-transpartent overlay.
const wrapInContainer = (loading, children, loader) => {
  if (children) {
    return (
      <div className={styles.container}>
        {children}
        {loading && <div className={styles.overlay}>{loader}</div>}
      </div>
    );
  }

  return loading && loader;
};

const Loader = ({ loading, extraClasses, children }) =>
  wrapInContainer(
    loading,
    children,
    <div
      className={withExtraClasses(styles, [
        'loader',
        ...extraClasses,
        !children && 'absolute',
      ])}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 20 20"
        className={styles.spinner}
      >
        <circle
          cx="10"
          cy="10"
          r="8"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          pathLength="10"
          strokeDasharray="8 2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );

Loader.defaultProps = {
  extraClasses: [],
};

Loader.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  extraClasses: PropTypes.arrayOf(PropTypes.string),
};

export default Loader;
