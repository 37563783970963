import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import IconChevronDown from '@svg/icon-chevron-down.svg';
import styles from './Header.module.scss';

const Header = ({ logo, websiteUrl, scrollToVacancies }) => {
  const { t } = useTranslation('common');

  const handleScrollToVacancies = e => {
    e.preventDefault();

    const el = document.querySelector('#vacancies');

    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        {logo && (
          <div className={styles.logo}>
            <img src={logo} alt="" />
          </div>
        )}

        {scrollToVacancies ? (
          <a
            className={styles.link}
            href="#vacancies"
            onClick={handleScrollToVacancies}
          >
            {t('viewTheVacancies')}
            <IconChevronDown className={styles.linkIcon} />
          </a>
        ) : (
          <Link href="/">
            <a className={styles.link}>{t('viewTheVacancies')} &raquo; </a>
          </Link>
        )}

        <a className={styles.websiteUrl} href={websiteUrl || '/'}>
          &laquo; {t('backToCompanyWebsite')}
        </a>
      </div>
    </header>
  );
};

export default Header;

Header.defaultProps = {
  scrollToVacancies: false,
};

Header.propTypes = {
  logo: PropTypes.string,
  websiteUrl: PropTypes.string,
  scrollToVacancies: PropTypes.bool,
};
