import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import SelectInput from '@molecules/SelectInput/SelectInput';
import styles from './VacanciesFilter.module.scss';

const VacanciesFilter = ({ selectOptions, onChange }) => {
  const { t } = useTranslation(['common']);

  return (
    <div className={styles.filter}>
      <SelectInput
        id="cities"
        name="cities"
        label={t('selectCity.label')}
        placeholder={t('selectCity.placeholder')}
        options={selectOptions}
        onChange={onChange}
      />
    </div>
  );
};

VacanciesFilter.propTypes = {
  selectOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default VacanciesFilter;
