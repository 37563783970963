import React from 'react';
import PropTypes from 'prop-types';
import { setVacancyUrl, useVacancies } from '@utils';
import { useTranslation } from 'next-i18next';
import Error from '@molecules/Error/Error';
import Loader from '@molecules/Loader/Loader';
import VacancyListItem from '@molecules/VacancyListItem/VacancyListItem';
import VacanciesFilter from '@organisms/VacanciesFilter/VacanciesFilter';
import styles from './VacancyList.module.scss';

const VacancyList = ({ companyId }) => {
  const { t } = useTranslation(['common']);
  const {
    vacancies,
    numberOfVacancies,
    vacancyCities,
    handleSelectCity,
    loading,
    error,
  } = useVacancies({
    companyId,
  });

  return (
    <div className={styles.container}>
      <h2 id="vacancies" className={styles.title}>
        {t('ourVacancies')}
      </h2>

      {vacancyCities?.length > 1 && (
        <VacanciesFilter
          selectOptions={vacancyCities}
          onChange={handleSelectCity}
        />
      )}

      <div className={styles.listContainer}>
        {!loading && error && <Error error={error} />}

        <Loader loading={loading}>
          {!loading && numberOfVacancies === 0 && <div>{t('noVacancies')}</div>}

          {!loading && !!vacancies && (
            <ul className={styles.list}>
              {vacancies.map(
                ({
                  _source: {
                    id,
                    slug,
                    title,
                    companyBranch: { name: company },
                    address: { city },
                    employmentType: { name: employmentType },
                  },
                }) => (
                  <li key={id} className={styles.item}>
                    <VacancyListItem
                      title={title}
                      href={setVacancyUrl(id, slug, city)}
                      company={company}
                      city={city}
                      employmentType={employmentType}
                    />
                  </li>
                )
              )}
            </ul>
          )}
        </Loader>
      </div>
    </div>
  );
};

VacancyList.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default VacancyList;
