import React from 'react';
import PropTypes from 'prop-types';
import { withExtraClasses } from '@utils';
import styles from './Card.module.scss';

const Card = ({ children, extraClasses }) => (
  <div className={withExtraClasses(styles, ['card', ...extraClasses])}>
    <div className={styles.content}>{children}</div>
  </div>
);

Card.defaultProps = {
  extraClasses: [],
};

Card.propTypes = {
  children: PropTypes.element.isRequired,
  extraClasses: PropTypes.arrayOf(PropTypes.string),
};

export default Card;
