import React from 'react';
import PropTypes from 'prop-types';
import withDomainServerSideProps from '@lib/withDomainServerSideProps';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Seo from '@molecules/Seo/Seo';
import PageIntro from '@organisms/PageIntro/PageIntro';
import Header from '@organisms/Header/Header';
import Hero from '@organisms/Hero/Hero';
import VacancyList from '@organisms/VacancyList/VacancyList';

const HomePage = ({ websiteSettings }) => {
  const { t } = useTranslation(['home', 'common']);
  const {
    heroImage,
    companyId,
    companyName,
    companyLogo,
    metaTitle,
    metaDescription,
    title,
    intro,
    websiteUrl,
  } = websiteSettings;
  const pageTitle = metaTitle || t('pageTitle', { companyName });
  const pageDescription =
    metaDescription || t('pageDescription', { companyName });

  return (
    <>
      <Seo title={pageTitle} description={pageDescription} />
      <Header logo={companyLogo} websiteUrl={websiteUrl} scrollToVacancies />
      <Hero image={heroImage || '/img/hero-fallback.jpg'} />
      <PageIntro title={title} intro={intro} />
      <VacancyList companyId={companyId} />
    </>
  );
};

export const getServerSideProps = withDomainServerSideProps(
  async ({ res, locale }) => {
    // Cache this SSR page using cache control, https://web.dev/stale-while-revalidate/
    res.setHeader(
      'Cache-Control',
      'public, s-maxage=300, stale-while-revalidate=600'
    );

    return {
      props: {
        ...(await serverSideTranslations(locale, ['common', 'home'])),
      },
    };
  }
);

export default HomePage;

HomePage.propTypes = {
  websiteSettings: PropTypes.shape({
    heroImage: PropTypes.string,
    companyId: PropTypes.string,
    companyName: PropTypes.string,
    companyLogo: PropTypes.string,
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    title: PropTypes.string,
    intro: PropTypes.string,
    websiteUrl: PropTypes.string,
  }),
};
